import React, { useEffect } from "react";
// import Countdown from "react-countdown";

export default function Landing() {
    useEffect(() => {
        document.title = "DISLY | Temporary Pause - We'll Be Back Soon!";
        const appHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty("--app-height", `${window.innerHeight}px`);
        };
        window.addEventListener("resize", appHeight);
        appHeight();

        return () => {
            window.removeEventListener("resize", appHeight);
        };
    }, []);

    // Commented out countdown renderer
    /*
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>We're working hard to bring Disly back online!</span>;
        } else {
            return (
                <>
                    <div className="timer__col">
                        <div className="timer__col-num">{days}</div>
                        <div className="timer__col-lab">days</div>
                    </div>
                    <div className="timer__col">
                        <div className="timer__col-num">{hours}</div>
                        <div className="timer__col-lab">hours</div>
                    </div>
                    <div className="timer__col">
                        <div className="timer__col-num">{minutes}</div>
                        <div className="timer__col-lab">minutes</div>
                    </div>
                    <div className="timer__col">
                        <div className="timer__col-num">{seconds}</div>
                        <div className="timer__col-lab">seconds</div>
                    </div>
                </>
            );
        }
    };
    */

    return (
        <div className="landing">
            <div className="auto__container">
                <div className="landing__inner">
                    <div className="landing__logo">
                        <img src={process.env.PUBLIC_URL + "/images/logo.svg?1686072628"} alt="logo" />
                    </div>
                    <h1>We're Taking a Short Intermission!</h1>
                    <p>
                        The show must go on, and we'll be back stronger than ever!
                    </p>
                    {/* Countdown timer commented out
                    <div className="timer">
                        <Countdown renderer={renderer} date={Date.now() + 30 * 24 * 60 * 60 * 1000} />
                    </div>
                    */}
                    <p>
                        Exciting things are happening behind the scenes! We're working 
                        tirelessly to bring you an even more extraordinary Disly experience.
                    </p>
                    {/* Donation button commented out
                    <button className="donation-button" onClick={handleDonation}>
                        Support Disly's Comeback
                    </button>
                    */}
                    <p className="engagement-text">
                        We value your interest and support! If you have any questions about our platform 
                        or would like to explore ways to support Disly during this transition, we'd love to hear from you. 
                        Your insights and support are crucial as we work on enhancing your future Disly experience.
                    </p>
                    <p>
                        Contact us at <a href="mailto:hello@disly.ee">hello@disly.ee</a>
                    </p>
                </div>
            </div>
            <div className="landing__shape">
                <img src={process.env.PUBLIC_URL + "/images/shapes.svg"} alt="shapes" />
            </div>
        </div>
    );
}